"use client";

import React from "react";
import { usePathname, useRouter } from "next/navigation";
import { composeClassName } from "@/utilities/general.util";
import Image from "next/image";
import { Dropdown, Space } from "antd";
import {
  MenuOutlined,
  HomeOutlined,
  ProfileOutlined,
  UserAddOutlined,
  SendOutlined,
} from "@ant-design/icons";
import Link from "next/link";
import useResponsive from "@/hooks/useResponsive";
import logoImage from "@/assets/images/logo.png";

const MenuItems: {
  label: string;
  href: string;
  key: string;
  icon?: React.ReactNode;
}[] = [
  {
    label: "Trang chủ",
    href: "/",
    key: "/",
    icon: <HomeOutlined />,
  },
  {
    label: "Gửi yêu cầu cứu trợ",
    href: "/request-support",
    key: "/request-support",
    icon: <SendOutlined />,
  },
  {
    label: "Danh sách yêu cầu",
    href: "/requests",
    key: "/requests",
    icon: <ProfileOutlined />,
  },
  {
    label: "Danh sách TNV",
    href: "/volunteers",
    key: "/volunteers",
    icon: <UserAddOutlined />,
  },
];

export default function LayoutHeader() {
  const { isMd } = useResponsive();
  const pathname = usePathname();
  const router = useRouter();

  const menu = MenuItems.map((menuItem) => {
    return {
      key: menuItem.key,
      label: (
        <button
          className={composeClassName(
            "flex w-[240px] h-[45px] gap-2 p-2 justify-center text-center text-primary items-center align-center border border-t-primary border-b-primary  border-r-primary first-of-type:border-l-primary first-of-type:rounded-l-lg last-of-type:rounded-r-lg hover:bg-hover hover:text-white hover:cursor-pointer",
            menuItem.href === pathname && "bg-primary text-white"
          )}
        >
          {menuItem.icon} {menuItem.label}
        </button>
      ),
    };
  });

  return (
    <div
      className="w-full bg-white fixed z-[51] py-2"
      style={{ boxShadow: "0 0 16px rgba(0, 0, 0, .2)" }}
    >
      <header className="w-full flex flex-row justify-center bg-white">
        <div className="container flex justify-between max-md:px-4">
          <Link className="" href={"/"}>
            <Image
              src={logoImage}
              alt="logo"
              width={50}
              height={50}
              className="md:hidden"
            />
            <Image
              src={logoImage}
              alt="logo"
              width={80}
              height={80}
              className="max-md:hidden"
            />
          </Link>
          {/* PC menu */}
          <div className="hidden lg:flex align-center items-center self-center">
            {MenuItems.map((menuItem) => (
              <button
                className={composeClassName(
                  "flex w-[240px] h-[45px] gap-2 p-2 justify-center text-center text-primary items-center align-center border border-t-primary border-b-primary  border-r-primary first-of-type:border-l-primary first-of-type:rounded-l-lg last-of-type:border-l-0 last-of-type:rounded-r-lg hover:bg-hover hover:text-white hover:cursor-pointer",
                  menuItem.href === pathname && "bg-primary text-white"
                )}
                onClick={() => {
                  router.push(menuItem.href);
                }}
                key={menuItem.href}
              >
                {menuItem.icon} {menuItem.label}
              </button>
            ))}
          </div>
          {/* Mobile Menu */}
          <Dropdown
            className="self-center lg:hidden"
            menu={{
              items: menu,
              onClick: ({ key }) => {
                router.push(key);
              },
            }}
            trigger={["click"]}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space className="text-black text-2xl">
                <MenuOutlined />
              </Space>
            </a>
          </Dropdown>
        </div>
      </header>
    </div>
  );
}
