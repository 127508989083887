"use client";

import { services } from "@/services";
import { setProvincesLocal, setRequestTypeLocal } from "@/utilities/configs.util";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { useEffect } from "react";

type Props = {
  children: React.ReactNode;
};

const queryClient = new QueryClient();

const LayoutMain = ({ children }: Props) => {
  useEffect(() => {
    getListConfig();
  }, [])

  const getListConfig = async () => {
    try {
      const res: any = await services.configsApi.getConfigs();

      if (res.data) {
        if (res.data.provinces) {
          setProvincesLocal(res.data.provinces);
        }

        if (res.data.requestTypes) {
          setRequestTypeLocal(res.data.requestTypes);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default LayoutMain;
